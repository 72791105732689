import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Large, colors } from 'js-components'
import { PageTitle, PageTitle2, PageTitle3, PageParagraph, PageRegular, PageLabel, PageSmall } from '../PageTitle';
import CompanyLogos from './companyLogos.png';

function LogoBanner() {
  return (
    <LogoBannerContainer>
      <LogoBannerSection>
        <PageLabel color={colors.darkGray}>Trusted By Leading Sales Teams</PageLabel>
        <LogoImg src="https://truly-media.s3.amazonaws.com/logos_safe.png"/>
      </LogoBannerSection>
    </LogoBannerContainer>
  );
}
const LogoBannerContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

const LogoBannerSection = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  margin: 0 auto;
  max-width: 1024px;
  height: 100px;
  background-color: white;
  z-index: 9;
  filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.1));
`

const LogoImg = styled.img`
  width: 70vw;
  max-width: 500px;
`

export default LogoBanner;