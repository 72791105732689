import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Box, colors, Button, Large } from 'js-components';
import { Section, WrapperBackground, WrapperSolid } from '../Wrapper';
import { requestDemo } from '../../common/Util';
import Confirmation from './confirmation.svg';
import Denial from './denial.svg';
import { PageTitle, PageTitle2, PageTitle3, PageParagraph, PageRegular, PageLabel, PageSmall } from '../PageTitle';

function BeforeAfter() {
  return (
    <BeforeAfterSection>
      <BeforeAfterPanel>
        <PageTitle3 ml="8px" pb="8px" color={colors.alertRed}>Before Truly</PageTitle3>
        <BeforeAfterItem>
          <img src={Denial}/>
          <BeforeAfterText color={colors.alertRed}>Reps don't update CRM</BeforeAfterText>
        </BeforeAfterItem>
        <BeforeAfterItem>
          <img src={Denial}/>
          <BeforeAfterText color={colors.alertRed}>Fraction of deals reviewed reactively</BeforeAfterText>
        </BeforeAfterItem>
        <BeforeAfterItem>
          <img src={Denial}/>
          <BeforeAfterText color={colors.alertRed}>Bottoms up forecasting in spreadsheets</BeforeAfterText>
        </BeforeAfterItem>
        <BeforeAfterItem>
          <img src={Denial}/>
          <BeforeAfterText color={colors.alertRed}>No idea who follows the process</BeforeAfterText>
        </BeforeAfterItem>
        <BeforeAfterItem>
          <img src={Denial}/>
          <BeforeAfterText color={colors.alertRed}>Cookie Cutter Sequences</BeforeAfterText>
        </BeforeAfterItem>
         <BeforeAfterItem>
          <img src={Denial}/>
          <BeforeAfterText color={colors.alertRed}>Data Silos + Spreadsheets</BeforeAfterText>
        </BeforeAfterItem>
      </BeforeAfterPanel>
      <BeforeAfterPanel>
        <PageTitle3 ml="8px" pb="8px" color={colors.trulyBlue}>After Truly</PageTitle3>
        <BeforeAfterItem>
          <img src={Confirmation}/>
          <BeforeAfterText color={colors.trulyBlue}>CRM Updates Itself</BeforeAfterText>
        </BeforeAfterItem>
        <BeforeAfterItem>
          <img src={Confirmation}/>
          <BeforeAfterText color={colors.trulyBlue}>All deals reviewed continuously</BeforeAfterText>
        </BeforeAfterItem>
        <BeforeAfterItem>
          <img src={Confirmation}/>
          <BeforeAfterText color={colors.trulyBlue}>Automated forecasting in CRM </BeforeAfterText>
        </BeforeAfterItem>
        <BeforeAfterItem>
          <img src={Confirmation}/>
          <BeforeAfterText color={colors.trulyBlue}>Know if the process actually works</BeforeAfterText>
        </BeforeAfterItem>
        <BeforeAfterItem>
          <img src={Confirmation}/>
          <BeforeAfterText color={colors.trulyBlue}>Adaptive, Guided Sales Journeys</BeforeAfterText>
        </BeforeAfterItem>
        <BeforeAfterItem>
          <img src={Confirmation}/>
          <BeforeAfterText color={colors.trulyBlue}>Salesforce is the source of truth</BeforeAfterText>
        </BeforeAfterItem>
      </BeforeAfterPanel>
    </BeforeAfterSection>
  );
}

const BeforeAfterSection = styled.div`
  display: flex;
  align-items: top;
  flex-direction: row;
  width: 1024px;

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 64px 32px 32px 32px;
    width: auto;
  }
`

const BeforeAfterText = styled(PageParagraph)`

`

const BeforeAfterPanel = styled.div`
  width: 50%;
  padding: 0 16px 0 16px;
  flex-grow: 0;
  flex-shrink: 0;

  @media (max-width: 1024px) {
    width: 100%;
    padding-bottom: 32px;
  }
`

const BeforeAfterItem = styled.div`
  color: white;
  display: flex;
  align-items: center;
`

export default BeforeAfter;