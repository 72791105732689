import styled from 'styled-components'
import { colors } from 'js-components'
import { Box } from 'js-components'

const Banner = styled.div`
  width: 100%;
  height: 64px;
  font-size: 16pt;
  line-height: 64px;
  background-color: ${colors.trulyBlue};
  color: white;
  font-family: "Josefin Sans";
  text-align: center;
  vertical-align: middle;

  a{
    color: white;
    text-decoration: underline;
  }

  @media (max-width: 648px) {
    font-size: 11pt;
  }
`

export default Banner