import React, { Component } from 'react';
import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';
import { Box, colors, Button, Large, Small } from 'js-components';
import { Section, WrapperBackground, WrapperSolid } from '../components/Wrapper';
import { VideoFrame } from '../components/VideoFrame';
import { TestimonialCard, TestimonialGrid } from '../components/TestimonialGrid';
import { CallToAction, GetStarted } from '../components/CallToAction';
import { CustomerCardGroup } from '../components/CustomerCard';
import Banner from '../components/Banner';
import PercentLinks from '../components/PercentLinks';
import { LogoBanner } from '../components/LogoBanner';
import { PageTitle, PageTitle2, PageTitle3, PageParagraph, PageRegular, PageLabel, PageSmall } from '../components/PageTitle';
import { Helmet } from 'react-helmet';

import GuageWeb from './animations/guageWeb.json';
import AnimatedIcons from './animations/animatedIcons.json';
import HeroBackground from './animations/heroBackground.json';

import DeviceLineup from './images/deviceLineup.png';
import SocialMediaPreview from './images/socialMediaPreview.png';
import DesktopSalesforce from './images/desktopSalesforce.png';
import CompanyLogos from './images/companyLogos.png';
import HeroDevices from './images/heroDevices.svg';

import PartnersBanner from './images/partnersBanner.png';

import LearnAcademy from './images/learnAcademy.png';
import LearnCommunity from './images/learnCommunity.png';
import LearnNewsletter from './images/learnNewsletter.png';

import RoleEnablement from './images/roleEnablement.png';
import RoleLeadership from './images/roleLeadership.png';
import RoleAnalytics from './images/roleAnalytics.png';
import RoleRevOps from './images/roleRevOps.png';

import SmallCaret from './images/smallCaret.svg';

import RobotIconUpdates from './images/robotIconUpdates.svg';
import RobotIconLinkedIn from './images/robotIconLinkedIn.svg';
import RobotIconPersonalized from './images/robotIconPersonalized.svg';
import RobotIconHeadset from './images/robotIconHeadset.svg';
import RobotIconMonitor from './images/robotIconMonitor.svg';
import RobotIconCheck from './images/robotIconCheck.svg';
import RobotIconFunnel from './images/robotIconFunnel.svg';
import RobotIconContacts from './images/robotIconContacts.svg';

import OldWay from './images/oldWay.svg';
import NewWay from './images/newWay.svg';

import G2CrowdTraits from './images/g2CrowdTraits.svg';
import FiveStars from './images/5stars.svg';

import SalesforceDashboard from './images/salesforceDashboard.png';
import revenueLifecycle from './images/revenueLifecycle.png';

import SmsIcon from './images/smsIcon.svg';
import PhoneSystemIcon from './images/phoneSystemIcon.svg';
import EmailIcon from './images/emailIcon.svg';
import WebMeetingsIcon from './images/webMeetingsIcon.svg';

import DataAnalysis from './images/dataAnalysis.svg';
import TeamDash from './images/teamDash.svg';
import ActionEvent from './images/actionEvent.svg';

import DemoVideo from './videos/scenesComposite.mp4';
import Default from '../components/Layouts/Default';
import { requestDemo } from '../common/Util';
import { whatIsRPA } from '../common/Util';
import { whatIsActivityIntelligence } from '../common/Util';
import { howToEliminateDataEntry } from '../common/Util';
import { byRole } from '../common/Util';
import { whatIsSalesProcessMining } from '../common/Util';
import { whatAreAIBots } from '../common/Util';
import { understandProcessEffectiveness } from '../common/Util';
import { whatIsHyperautomation } from '../common/Util';

import { BeforeAfter } from '../components/BeforeAfter';


export default class IndexPage extends Component {
  constructor(props) {
    super(props);
    this.animationIsAttached = false;
  }

  componentDidMount() {
    this.attachAnimation();
  }

  attachAnimation() {
    if (this.gaugeWebAnimation !== undefined && this.iconAnimation !== undefined && !this.animationIsAttached) {
      const gaugeProps = {
        container: this.gaugeWebAnimation,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: GuageWeb
      };

      const iconProps = {
        container: this.iconAnimation,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: AnimatedIcons
      };

      const heroProps = {
        container: this.heroBackground,
        renderer: 'svg',
        preserveAspectRatio: 'xMidYMid slice',
        loop: true,
        autoplay: true,
        animationData: HeroBackground
      };

      window.lottie.loadAnimation(gaugeProps);
      window.lottie.loadAnimation(iconProps);
      window.lottie.loadAnimation(heroProps);
    }
  }

  render() {
    return (
      <Default>
        <Helmet>
          <meta charset="UTF-8" />
          <title>Truly | Automate Any Revenue Process With AI-Enabled Bots</title>
          <meta
            name="description"
            content="Eliminate complex repetitive tasks, reduce human error and continuously improve CRM data quality with hyperautomation."
          />
          <meta property="og:title" content="Truly" />
          <meta
            property="og:description"
            content="Sales Process Optimization. Mine your activity data, automatically update CRM fields, generate funnel metrics and automate management processes."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="http://www.truly.co" />
          <meta property="og:image" content={`https://truly.co${SocialMediaPreview}`} />
        </Helmet>

        <WrapperBackground background="revenueRobots" gradientType="lightBlue">

          <BackgroundAnimation>
            <div
              ref={animationDiv => {
                this.heroBackground = animationDiv;
              }}
            />
          </BackgroundAnimation>
          <Section flexDirection={['column', 'row']} pt="3rem" pb="7rem" background={colors.trulyDark}>
            <Box width={[1, 3 / 4]}>
              <PageTitle mb="2rem" color={colors.trulyDark}>
              Automate Any Revenue Process <br/> With AI-Enabled Bots
              </PageTitle>

              <PageParagraph mb="1.5rem" color={colors.trulyDark} center>
                Eliminate complex repetitive tasks, reduce human error and continuously improve CRM data quality with hyperautomation<br/>
              </PageParagraph>

              <VideoFrame caption=" " background="" videoLink="https://player.vimeo.com/video/786032475" />

              <PageLabel color={colors.trulyDark} mt="32px" mb="22px">trusted by leading sales teams</PageLabel>
              <WidthImg src={CompanyLogos} />

            </Box>
          </Section>
        </WrapperBackground>

        <WrapperSolid gradientType="white">
          <Section flexDirection={['column', 'row']} mt="0" m={['1rem', '2rem', '5rem']}>
            <PageTitle3 center pb="22px" color={colors.trulyDark}>What Can Revenue Robots Do For You?</PageTitle3>
            <FeatureGrid>

              <FeatureTile>
                <GatsbyLink to="/solutions/automate-linkedin-prospecting">
                <FeatureIcon src={RobotIconLinkedIn} />
                <PageParagraph color={colors.trulyDark}>Automate <br/> Prospecting</PageParagraph>
                </GatsbyLink>
              </FeatureTile>

              <FeatureTile>
                <GatsbyLink to="/solutions/better-than-a-contact-data-provider">
                <FeatureIcon src={RobotIconMonitor} />
                <PageParagraph color={colors.trulyDark}>Enrich & Validate Contact Info</PageParagraph>
                </GatsbyLink>
              </FeatureTile>


              <FeatureTile>
                <GatsbyLink to="/solutions/automate-lifecycle-marketing">
                <FeatureIcon src={RobotIconPersonalized} />
                <PageParagraph color={colors.trulyDark}>Build Segments & Orchestrate Journeys</PageParagraph>
                </GatsbyLink>
              </FeatureTile>

              <FeatureTile>
                <GatsbyLink to="/solutions/eliminate-sales-rep-data-entry">
                <FeatureIcon src={RobotIconFunnel} />
                <PageParagraph color={colors.trulyDark}>Eliminate Manual<br />CRM Updates</PageParagraph>
                </GatsbyLink>
              </FeatureTile>

              <FeatureTile>
                <GatsbyLink to="/solutions/mine-your-sales-activity-data">
                <FeatureIcon src={RobotIconUpdates} />
                <PageParagraph color={colors.trulyDark}>Understand Process Effectiveness</PageParagraph>
                </GatsbyLink>
              </FeatureTile>

              <FeatureTile>
                <GatsbyLink to="/solutions/account-opportunity-health-scoring">
                <FeatureIcon src={RobotIconCheck} />
                <PageParagraph color={colors.trulyDark}>Monitor Customer<br />& Deal Health</PageParagraph>
                </GatsbyLink>
              </FeatureTile>

              <FeatureTile>
                <GatsbyLink to="/about">
                <FeatureIcon src={RobotIconHeadset} />
                <PageParagraph color={colors.trulyDark}>Guide Sellers To Right Next Steps</PageParagraph>
                </GatsbyLink>
              </FeatureTile>

              <FeatureTile>
                <GatsbyLink to="/solutions/guided-automated-selling">
                <FeatureIcon src={RobotIconContacts} />
                <PageParagraph color={colors.trulyDark}>Monitor CRM Data Quality & Auto Cleanse</PageParagraph>
                </GatsbyLink>
              </FeatureTile>

  
            </FeatureGrid>
          </Section>
        </WrapperSolid>

        <WrapperBackground background="boardroom" gradientType="lightBlue">
          <Section flexDirection={['column', 'row']} mt="0" m={['1rem', '2rem', '5rem']}>
            <Box width={[1, 1/2]} mb="16pt">
              <PageTitle3 center color={colors.trulyDark}>
              Built for the Entire Revenue Team
              </PageTitle3>
              <PageParagraph as="p" color={colors.trulyDark} mb="1.5rem" mt="1.5rem">
              Whether you’re in RevOps, Sales or Management,<br />hyperautomation can give you back hours each day.
              </PageParagraph>
            </Box>

            <FeatureGrid>

              <RoleTileContainer>
                <GatsbyLink to="/solutions/for-revenue-ops">
                <RoleTile>
                  <RoleImg src={RoleRevOps} />
                  <PageLabel mb="8px">REVOPS</PageLabel>
                  <PageSmall>Get perfect process adherence and visibility. Automate  all the jobs your reps hate doing.</PageSmall>
                </RoleTile>
                </GatsbyLink>
              </RoleTileContainer>

              <RoleTileContainer>
                <GatsbyLink to="/solutions/for-analytics-teams/">
                <RoleTile>
                  <RoleImg src={RoleAnalytics} />
                  <PageLabel mb="8px">ANALYTICS</PageLabel>
                  <PageSmall>Never regret a CRM design decision again. Let robots continuously analyze and backfill data without eng resources.</PageSmall>
                </RoleTile>
                </GatsbyLink>
              </RoleTileContainer>

              <RoleTileContainer>
                <GatsbyLink to="/solutions/for-sales-enablement">
                <RoleTile>
                  <RoleImg src={RoleEnablement} />
                  <PageLabel mb="8px">MARKETING</PageLabel>
                  <PageSmall>Know exactly where your market, prospects and deals are in the funnel without relying on sales to update CRM.</PageSmall>
                </RoleTile>
                </GatsbyLink>
              </RoleTileContainer>

              <RoleTileContainer>
                <GatsbyLink to="/solutions/for-sales-leaders">
                <RoleTile>
                  <RoleImg src={RoleLeadership} />
                  <PageLabel mb="8px">SALES</PageLabel>
                  <PageSmall>Focus on deal strategy & coaching.  Let robots monitor the pipeline and surface risks according to your custom process.  </PageSmall>
                </RoleTile>
                </GatsbyLink>
              </RoleTileContainer>

            </FeatureGrid>

          </Section>
      </WrapperBackground>

      <WrapperSolid gradientType="white">
          <Section flexDirection={['column', 'row']} mt="0" m={['1rem', '2rem', '5rem']}>
            <PageTitle3 center pb="22px" color={colors.trulyDark}>Join The Experts In RevOps & Hyperautomation</PageTitle3>
            <FeatureGrid>

              <LearnTileContainer>
                <GatsbyLink to="https://revops-academy.truly.co">
                <LearnTile>
                  <WidthImg src={LearnAcademy}/>
                  <LearnText>
                  <PageLabel py="16px">RevOps Automation Academy</PageLabel>
                  <PageParagraph>Learn the latest technologies and techniques unlocking the next generation of revenue automation</PageParagraph>
                  </LearnText>
                  <LearnButton>Join The Academy</LearnButton>
                </LearnTile>
                </GatsbyLink>
              </LearnTileContainer>

              <LearnTileContainer>
                <GatsbyLink to="https://hello.truly.co/automation-heads-signup">
                <LearnTile>
                  <WidthImg src={LearnCommunity}/>
                  <LearnText>
                  <PageLabel py="16px">Automation Heads Community</PageLabel>
                  <PageParagraph>Join a slack group of automation-obsessed individuals pushing the boundaries of RevOps</PageParagraph>
                  </LearnText>
                  <LearnButton>Join The Community</LearnButton>
                </LearnTile>
                </GatsbyLink>
              </LearnTileContainer>

              <LearnTileContainer>
                <GatsbyLink to="https://www.linkedin.com/newsletters/automation-heads-weekly-6975403585060687872/">
                <LearnTile>
                  <WidthImg src={LearnNewsletter}/>
                  <LearnText>
                  <PageLabel py="16px">Newsletter</PageLabel>
                  <PageParagraph>Get weekly exclusive content including Cheat Sheets, Lessons and Gated Industry Research (Gartner, TechCrunch & More)</PageParagraph>
                  </LearnText>
                  <LearnButton>Sign Up</LearnButton>
                </LearnTile>
                </GatsbyLink>
              </LearnTileContainer>
  
            </FeatureGrid>
          </Section>
        </WrapperSolid>


          <WrapperBackground center color={colors.trulyDark} background="managedByQ" gradientType="lightBlue">
          <Section background={colors.trulyDark} flexDirection="column" maxWidth="100%">
            <Box>
                <PageTitle3 color={colors.trulyDark} mb="2rem" mt="2rem">
                Built for RevOps, Loved By Sales.
              </PageTitle3>
            </Box>
            <TestimonialGrid>
              <TestimonialCard avatar="michael" name="Michael M" company="WeWork" quote="Before Truly, activity data in our Salesforce org wasn't very reliable or even usable.  Now our CRM really is where I go to get the data I need, and I don't need to double check it against 3-4 different systems.  It just works."/>
              <TestimonialCard avatar="noah" name="Noah S" company="Flexport" quote="Love the Truly <> SFDC integration -- it is seriously one of the best integrations with SFDC that I've seen...and it a tremendous help to my workflow"/>
              <TestimonialCard avatar="jackieL" name="Jacki Leahy" company="Eustace" quote="On the surface, it's so simple.  You deploy it in no time and users just use it without much training/thought.  And then you see what's happening under the hood... extremely powerful."/>
            </TestimonialGrid>
          </Section>
        </WrapperBackground>

      <WrapperBackground background="peopleJumping" gradientType="darkBlue">
        <WidthImg src={PartnersBanner}/>
      </WrapperBackground>

      <WrapperBackground background="sf" gradientType="black">
        <GetStarted/>
      </WrapperBackground>

      </Default>
    );
  }
}

const CustomerCardContainer = styled.div`
display: flex;
flex-direction: row;
align-items: stretch;
@media (max-width: 50em) {
  flex-direction: column;
}
`;

const VideoTrio = styled.div`
display: flex;
flex-direction: row;
align-items: stretch;
@media (max-width: 50em) {
  flex-direction: column;
}
`;

const CustomerCard = styled.div`
  background-color: white;
  width: 33%;
  border-radius: 8pt;
  margin: 8px;
  height: auto;
  flex-grow: 0;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: 16pt 8pt;
  text-align: left;
  justify-content: left;
  align-items: top;
  color: black;
  @media (max-width: 50em) {
    width: 100%;
  }
`

const IconRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16pt;
  text-align: left;
  justify-content: left;
  align-items: center;
  color: black;

  :hover {
    opacity: 1;
    transition: 0.3s all;
    cursor: pointer;
    background: #F4F4F4;
  }
`


const Icon = styled.img`
  width: 60pt;
  height: 60pt;
  padding: 8pt;
  margin-right: 8pt;
`

const TextWrapper = styled.div`
  width: 100%;
`


const Table = styled.div`
  background-color: white;
  border-radius: 8pt;
  overflow: hidden;
`;

const DividerLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #cbd0d8;
`;

const HeroImg = styled.img`
  width: 80%;
  position: absolute;
  max-width: 760pt;
  transform: translateY(-70%) translateX(-50%);
`;

const MainVideo = styled.video`
  width: 25%;
  height: auto;
  max-width: 240pt;
  border-radius: 4pt;
  overflow: hidden;
  position: absolute;
  transform: translateY(-100%) translateX(-50%);
  box-shadow: 0pt 4pt 32pt rgba(0, 0, 0, 0.3);
`;

const InBetween = styled.div`
  display: relative;
  height: 0px;
  text-align: center;
  pointer-events: none;

  @media (max-width: 50em) {
    display: none;
  }
`;

const VideoRow = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;

  @media (max-width: 1080px) {
    flex-direction: column;
  }
`;

const BackgroundAnimation = styled.div`
  height: 100%;
  min-width: 200vh;
  position: absolute;
  z-index: -1;
  opacity: 0.2;
`;

const WidthImg = styled.img`
  width: 100%;
`;

const FeatureGrid = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const FeatureTile = styled.div`
  width: 25%;
  margin: 0;
  padding: 8px;
  border-radius: 8px;
  height: 140px;
  transition: 0.3s all;
  cursor: pointer;
  @media (max-width: 50em) {
    width: 50%;
  }

  :hover {
    transition: 0.3s all;
    transform: scale(1.05);
  }

  ${PageParagraph}{
    @media (max-width: 50em) {
      font-size: 10pt;
      font-weight: bold;
      line-height: 11pt;
    }
  }
`

const RoleTileContainer = styled.div`
padding: 8px;
width: 25%;

@media (max-width: 50em) {
  width: 100%;
}
`

const RoleTile = styled.div`
  width: 100%;
  margin: 0;
  padding: 8px 22px;
  height: 300px;
  color: ${colors.trulyDark};
  background-color: white;
  transition: 0.3s all;
  @media (max-width: 50em) {
    width: 100%;
    height: auto;
  }

  :hover {
    transition: 0.3s all;
    transform: scale(1.05);
  }
`

const RoleImg = styled.img`
  width: 82px;
  height 82px;
  margin: 16px;
  
`;

const RoleCaret = styled.img`
width: 28px;
height: 28px;
opacity: 0;
transition: 0.3s all;
margin-top:20px;

${RoleTile}:hover & {
  transform: scale(1.1);
  opacity: 1;
  transition: 0.3s all;
}
`

const LearnTileContainer = styled.div`
padding: 16px;
width: 33.33%;

@media (max-width: 50em) {
  width: 100%;
}
`

const LearnTile = styled.div`
width: 100%;
color: ${colors.trulyDark};
`

const LearnText = styled.div`
height: 180px;
width: 100%;

@media (max-width: 50em) {
  height: auto;
  margin-bottom: 16px;
}
`

const LearnButton = styled.div`
background-color: #CEF5FF;
border-radius: 26px;
width: 250px;
margin: 0 auto;
height: 52px;
text-align: center;
font-size: 19px;
font-weight: bold;
line-height: 52px;
transition: 0.3s all;


${LearnTile}:hover & {
  transform: scale(1.05);
  transition: 0.3s all;
}
`

const FeatureDesc = styled.div`

`
const FeatureIcon = styled.img`
  
`;
